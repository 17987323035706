import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const ProfileAuthentication = () => {
    return (
        <Layout>
            <Seo title="Profile Authentication" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="My Account" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="My Account" 
            />

            <div className="login ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="default-section-title">
                                <h3>Login</h3>
                            </div>
                            <div className="login-form pr-20">
                                <form>
                                    <div className="mb-3">
                                        <label 
                                            htmlFor="exampleInputEmail1" 
                                            className="form-label">User Name or Email address*
                                        </label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            id="exampleInputEmail1" 
                                            aria-describedby="emailHelp"
                                            required
                                        />
                                        <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password*</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="exampleInputPassword1" 
                                            required
                                        />
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input 
                                            type="checkbox" 
                                            className="form-check-input" 
                                            id="exampleCheck1"
                                        />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                    </div>
                                    <button type="submit" className="default-button default-button-3"><span>Log In</span></button>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="default-section-title pt-30">
                                <h3>Register</h3>
                            </div>
                            <div className="login-form">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputUsername" className="form-label">User Name*</label>
                                        <input 
                                            type="Text" 
                                            className="form-control" 
                                            id="exampleInputUsername" 
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail" className="form-label">Email address*</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            id="exampleInputEmail" 
                                            aria-describedby="emailHelp" required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword" className="form-label">Password*</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="exampleInputPassword" 
                                            required 
                                        />
                                    </div>
                                    <p>The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>
                                    <button type="submit" className="default-button default-button-3"><span>Register</span></button>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default ProfileAuthentication
